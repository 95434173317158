.display-none {
  display: none !important;
}

span.thank_you_email {
  color: #000 ;
  cursor: pointer;
}

.thank_you_email-content {
  min-width: 100%;
}

.thank_you_email-container {
  margin-top: 10%;
  
  .container {
    background-color: #fff;
    color: #000;
    max-width: 736px;
    max-height: 455px;
    border-radius: 24px;
  }
  .btn-upd-dashb {
    margin-bottom: 5%;
  }
}