.custom-date-time-picker {
  position: relative;

  .ant-picker-focused {
    box-shadow: none;
  }

  .switch-tooltip {
    bottom: 10px;
  }
}

.custom-date-time-picker-dropdown.ant-picker-dropdown {
  width: 598px;
  top: unset !important;
  bottom: 20px !important;
  left: calc(50% - 307px) !important;

  .ant-picker-time-panel {
    .ant-picker-header {
      height: 72px;
      margin-bottom: 0;

      .ant-picker-header-view {
        font-size: 20px !important;
        line-height: 30px !important;
      }
    }

    .ant-picker-content {
      height: 175px;
    }
  }

  .ant-picker-date-panel {
    .ant-picker-header {
      height: 40px;
      margin-bottom: 0;

      .ant-picker-header-view {
        font-size: 20px !important;
      }

      .ant-picker-header-next-btn,
      .ant-picker-header-super-next-btn,
      .ant-picker-header-prev-btn,
      .ant-picker-header-super-prev-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px !important;
      }
    }
  }

  .ant-picker-time-panel-cell {
    font-size: 16px !important;

    .ant-picker-time-panel-cell-inner {
      color: #000 !important;
      font-weight: 400;
    }

    &:hover {
      .ant-picker-time-panel-cell-inner {
        background: #000 !important;
        color: #fff !important;
      }
    }
  }

  .ant-picker-time-panel-cell-disabled {
    &:hover {
      .ant-picker-time-panel-cell-inner {
        background: #000 !important;
        color: #fff !important;
      }
    }

    .ant-picker-time-panel-cell-inner {
      color: #5a5a5a !important;
      background-color: #1f2126 !important;
      border-radius: 0 !important;
      cursor: default !important;
    }
  }

  .ant-picker-time-panel-cell-selected {
    &:hover {
      .ant-picker-time-panel-cell-inner {
        background: #c6c8d2 !important;
      }
    }

    .ant-picker-time-panel-cell-inner {
      background: var(--primary-color) !important;
      color: #fff !important;
      border-radius: 4px !important;
    }
  }

  td.ant-picker-cell {
    min-height: 28px !important;
    height: 28px !important;

    .ant-picker-cell-inner {
      font-size: 16px !important;
    }

    &::before {
      height: 28px;
    }
  }

  .ant-picker-footer {
    .ant-btn-primary {
      width: 50px;
      background: var(--primary-color);
    }
  }
}
