div.modal-header.custom-modal-header {
  width: 530px;
  padding: 0 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5.modal-title {
    margin: 45px 0 35px 0;
  }

  .close-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;

    i.la {
      color: #9699a2;
      font-size: 22px;
    }
  }
}

.modal-body.import-data-modal {
  width: 530px;
  padding: 24px 55px;
  font-size: 16px;
  font-weight: 400;
  color: #000;

  .note {
    color: #000;
  }

  .file {
    padding: 13px 17px 13px 24px;
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: #fff;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16) span {
      width: 285px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .remove-file {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #000;
      opacity: 0.5;
    }
  }
}

.modal-footer.chooseFile-area {
  width: 530px;

  button.btn-secondary.btn-table-inst.btn-comp.exportBtn.choose-file-btn {
    padding: 9px 35px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    border-radius: 110px;
  }

  button.btn-secondary.btn-table-inst.btn-comp.exportBtn.import-file-btn {
    padding: 8px 30px;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    border-radius: 110px;
  }

  .ant-upload-wrapper {
    display: flex;
    width: 100%;

    .ant-upload.ant-upload-drag {
      display: flex;
      text-align: right;
      width: 100%;
      border: none;
      background-color: transparent;

      .ant-upload.ant-upload-btn {
        padding: 0;

        .drop-file-text {
          margin-top: 10px;
          margin-bottom: 0;
          margin-right: 35px;
          font-weight: 400;
          font-size: 12px;
          color: #000;
          opacity: 0.5;
        }
      }
    }
  }
}