.topbar-inner-container {
  overflow-y: hidden;
}

.topbar-inner-container-row {
  white-space: nowrap;
  min-height: 62px;
}

.cloud-status-icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.grafana,
.cloud-ico {
  fill: #898b96;
}

.cloud-ico-ellipse {
  fill: #ff1c3c;
}

.m-btn--pill:hover {

  .grafana,
  .cloud-ico {
    fill: #fff;
  }
}

.dashb-page-info {
  color: #000;
  line-height: 62px;
}

.btn-upd-dashb button {
  padding-left: 21px;
  padding-right: 21px;
  color: var(--primary-color);
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0.27px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
}